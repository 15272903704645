var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    _vm._g(
      {
        staticClass: "choice-btn",
        class:
          ((_obj = {
            "choice-btn--selected": _vm.$attrs.selected,
            "choice-btn--inactive": _vm.$attrs.inactive,
            "choice-btn--correct": _vm.$attrs.correct,
            "choice-btn--wrong": _vm.$attrs.wrong,
            "choice-btn--secondary": _vm.$attrs.secondary,
          }),
          (_obj["choice-btn--" + _vm.$attrs.size] = _vm.$attrs.size),
          _obj),
      },
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }