var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { staticClass: "mission-options", class: _vm.classes }, [
        _c(
          "div",
          { staticClass: "mission-options__list" },
          _vm._l(_vm.options, function (option, index) {
            return _c(
              "div",
              { key: index, staticClass: "mission-options__list-option" },
              [_vm._t("option", null, { option: option, index: index })],
              2
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }