














import useEnlargedUI from "@/use/useEnlargedUI"
import Vue from "vue"

export default Vue.extend({
  name: "OptionsList",

  props: {
    options: {
      type: Array,
      default: undefined
    },
    show: {
      type: Boolean,
      default: undefined
    },
    autoWidth: {
      type: Boolean,
      default: undefined
    },
    columns: {
      type: Boolean,
      default: undefined
    }
  },
  setup() {
    const { enlargedUI } = useEnlargedUI()
    return { enlargedUI }
  },
  computed: {
    classes() {
      return {
        "mission-options--columns": this.columns,
        "mission-options--auto-width": this.autoWidth,
        "mission-options--presenter": this.isViewerPresenter,
        "mission-options--enlarged": this.enlargedUI
      }
    },
    isViewerPresenter() {
      return this.$store.getters["group/isViewerPresenter"]
    }
  }
})
